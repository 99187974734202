
<template>
<div class="main mk1">
    <div class="head_new">
         <router-link to="detail"><a href="" class="first" target="_blank">{{news1[0][0].title}}</a></router-link><div class="second" id="first_second">
      <span v-for="(item, i) in news1[1].slice(1, 4)" :key="i">
          <router-link to="detail">
              {{ item.title }}
            </router-link
          ><span v-if="i < 2">|</span>
        </span>
      </div>
    </div>
    <div class="tpxw fl">
        <div class="slider">
            <div class="slider-container">
                <div class="slider-wrapper" id="infoImg">
                <div class="slide"><a href="web/info/detail/8a405c957b70ea41017b7268f46e000a" target="_blank"><img src="http://abc.gongjiangbao.top/gyzj/upload_path/335e04fc7be26184f570500917618028.jpg" alt="未来五年，就业优先如何全面强化？"><span class="title">未来五年，就业优先如何全面强化？</span></a></div><div class="slide"><a href="web/info/detail/8a405c957b16ac39017b51d52d94005f" target="_blank"><img src="/static/upload/image/20210817/20210817095845_700.png" alt="失业补助金，现在领还不晚！"><span class="title">失业补助金，现在领还不晚！</span></a></div><div class="slide"><a href="web/info/detail/8a405c957a7f0433017aad01cb830067" target="_blank"><img src="http://www.mohrss.gov.cn/SYrlzyhshbzb/dongtaixinwen/buneiyaowen/hyhd/202107/W020210716256502403872.jpg" alt="李忠副部长出席金砖国家劳工就业部长会并发言"><span class="title">李忠副部长出席金砖国家劳工就业部长会并发言</span></a></div><div class="slide"><a href="web/info/detail/8a405c957a75bbd6017a796908850007" target="_blank"><img src="http://rst.sc.gov.cn/rst/zwyw/2021/7/5/d9f8987a21fb4fc8b0266e121805f7a7/images/f7df6d9ab8da497193a71175a20aa6f4.jpg" alt="2021年四川省高校毕业生“三支一扶”计划招募和卫生、中医药专业副高级技术资格考试顺利举行"><span class="title">2021年四川省高校毕业生“三支一扶”计划招募和卫生、中医药专业副高级技术资格考试顺利举行</span></a></div><div class="slide"><a href="web/info/detail/8a405c957a421f70017a5a84b4c8001b" target="_blank"><img src="http://rsj.luzhou.gov.cn/Upload/rsj/ContentManage/Article/image/2021/06/29/906f46d60cf549e29dbdfd9081a8fb7d.jpg" alt="泸州市人社局举行庆祝中国共产党成立100周年表扬大会"><span class="title">泸州市人社局举行庆祝中国共产党成立100周年表扬大会</span></a></div></div>
            </div>
        </div>
    </div>
    <div class="xw_dt fl">
      <div class="hd">
        <ul>
          <router-link to="news1">
            <li @mouseover="setTab(1, 0)" v-bind:class="{ hover: tab1 == 0 }">
              <a>新闻动态</a>
            </li>
          </router-link>
          <li><i>/</i></li>
          <router-link to="news2">
            <li @mouseover="setTab(1, 1)" v-bind:class="{ hover: tab1 == 1 }">
              <a>通知公告</a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="bd">
        <ul class="ulyd block" id="con_one_1">
          <li v-for="(item, i) in news1[tab1]" :key="i">
            <router-link to="detail"
              ><a href="" target="_blank">{{ item.title }}</a></router-link
            ><span>08/20</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="login fr">
      <form action="login" id="loginform" method="post">
        <h2><strong>学员登录</strong></h2>
        <input type="hidden" name="type" value="web" />
        <input
          type="text"
          id="username"
          name="username"
          class="text name"
          placeholder="身份证号码"
        />
        <input
          type="password"
          id="password"
          name="password"
          class="text password"
          placeholder="密码"
        />
        <div class="yzm">
          <input type="text" id="captcha" name="captcha" placeholder="验证码" />
          <img
            src="web/loginImage"
            id="imgcode"
            onclick="ResetImg()"
            style="cursor: pointer"
          />
          <div class="clear"></div>
        </div>
        <div class="wj_password">
          <a href="sweepCode" class="fl">电子社保卡扫码登录</a>
          <a href="web/findPwd" class="fr" style="color: red"
            ><u>忘记密码？</u></a
          >
          <div class="clear"></div>
        </div>
        <div class="btnbox">
          <input
            type="button"
            value="登 录"
            class="btn btn_dl"
            onclick="login()"
          />
          <input
            type="button"
            value="注 册"
            class="btn btn_zc"
            onclick="regUser()"
          />
        </div>
      </form>
    </div>

    <div class="clear"></div>
    <div class="img_xc luara-" style="width: 1200px; height: 104px">
      <ul style="width: 1200px; height: 104px">
        <li style="width: 1200px; height: 104px; display: block">
          <img src="@/assets/img_xc2.png" alt="2" />
        </li>
      </ul>
      <ol>
        <li class="seleted"></li>
        <li></li>
      </ol>
    </div>
  </div>

  <div class="mk2">
    <div class="main">
      <div class="xw_zc fl" style="margin-right: 40px; display: inline-block">
        <div class="hd">
          <ul>
            <router-link to="news1">
              <li @mouseover="setTab(2, 0)" v-bind:class="{ hover: tab2 == 0 }">
                <a>职称信息</a>
              </li>
            </router-link>
            <li><i>/</i></li>
            <router-link to="news2">
              <li @mouseover="setTab(2, 1)" v-bind:class="{ hover: tab2 == 1 }">
                <a>培训信息</a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="bd">
          <ul class="ulyd block" id="con_two_1">
            <li v-for="(item, i) in news2[tab2]" :key="i">
              <router-link to="detail"
                ><a href="" target="_blank">{{ item.title }}</a></router-link
              ><span>08/20</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="xw_zc fl">
        <div class="hd">
          <ul>
            <router-link to="news1">
              <li @mouseover="setTab(3, 0)" v-bind:class="{ hover: tab3 == 0 }">
                <a>政策法规</a>
              </li>
            </router-link>
            <li><i>/</i></li>
            <router-link to="news2">
              <li @mouseover="setTab(3, 1)" v-bind:class="{ hover: tab3 == 1 }">
                <a>时政新闻</a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="bd">
          <ul class="ulyd block" id="con_three_1" style="display: block">
            <li v-for="(item, i) in news3[tab3]" :key="i">
              <router-link to="detail"
                ><a href="" target="_blank">{{ item.title }}</a></router-link
              ><span>08/20</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="xw_btn fr">
        <a href="reg/course" style="margin-top: 0px">
          <i class="kccx"></i>
          <h2>课程查询</h2>
          <h3>Course Query</h3>
        </a>
        <a href="reg/study">
          <i class="zxxx"></i>
          <h2>在线学习</h2>
          <h3>Online Learning</h3>
        </a>
        <a href="reg/myExam">
          <i class="zxks"></i>
          <h2>在线考试</h2>
          <h3>Online Test</h3>
        </a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
  <div class="main mk3">
    <h1>培训课程</h1>
    <div class="kcbox" id="pxkc">
      <a href="" class="tp"
        ><div class="img"><img src="@/assets/course.png" /></div>
        <div class="nr">
          <p>（专业科目）农业科目培训课程【2021】</p>
          <i>免费</i><em>必修课程</em>
          <div class="clear"></div></div></a
      ><a href="" class="tp"
        ><div class="img"><img src="@/assets/course.png" /></div>
        <div class="nr">
          <p>（公需科目）【2021】</p>
          <i>免费</i><em>必修课程</em>
          <div class="clear"></div></div></a
      ><a href="web/courseInfo?id=40285c9b72a2975e0172fee8fdf300ed" class="tp"
        ><div class="img"><img src="@/assets/course.png" /></div>
        <div class="nr">
          <p>（职业技能）学习的控制</p>
          <i>免费</i><em>拓展课程</em>
          <div class="clear"></div></div></a
      ><a href="web/courseInfo?id=40285c9b72a2975e0172fee890a100ec" class="tp"
        ><div class="img"><img src="@/assets/course.png" /></div>
        <div class="nr">
          <p>（职业技能）有效学习的设计</p>
          <i>免费</i><em>拓展课程</em>
          <div class="clear"></div></div
      ></a>
      <div class="clear"></div>
    </div>
  </div>
</template>
<style>
.second a {
  max-width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tzbox .news li {
  min-height: 48px;
}
</style>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tab1: 0,
      tab2: 0,
      tab3: 0,
      news1: [
        [
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
          {
            title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
            time: "2021-08-20",
          },
        ],
        [
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
          {
            title: "从经济数据看建筑业发展态势",
            time: "2021-08-21",
          },
        ],
      ],
      news2: [],
      news3: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(params) {
      this.news2 = this.news1;
      this.news3 = this.news1;
    },
    setTab(a,b){
      switch (a) {
        case 1:
          this.tab1=b
          break;
        case 2:
          this.tab2=b
          break;
        case 3:
          this.tab3=b
          break;
      
        default:
          break;
      }
    }
  },
};
</script>