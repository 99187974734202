<template>
  <div class="banner">
    <div class="main">
      <router-link to="/contact">
      <a
        href=""
        style="
          float: right;
          color: white;
          font-size: 14px;
          margin-right: 8px;
          margin-top: 5px;
        "
        >联系我们</a
      >
      </router-link>
      <span style="float: right; color: white; margin-top: 5px"
        >&nbsp;|&nbsp;</span
      >
      <router-link to="/about">
      <a
        href=""
        style="float: right; color: white; font-size: 14px; margin-top: 5px"
        >关于我们</a
      >
      </router-link>
      <img src="@/assets/logo.png" class="logo fl" />
      <div class="search fr">
        <input
          type="text"
          placeholder="请输入搜索关键字"
          id="txt_search"
          value=""
        />
        <input type="button" onclick="search()" />
      </div>
      <div class="clear"></div>
    </div>
  </div>
  <div id="nav">
    <ul>
      <li v-for="(item, i) in navs" :key="i">
        <router-link :to="item.to">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
  <router-view />
  <div class="bottom">
    <div class="main">
      <p >
        Copyright@2021 四川大国工匠科技教育有限公司<br />
      <a style="width:100%;text-align:center" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >蜀ICP备20000385号-1</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        { name: "网站首页", to: "/" },
        { name: "新闻中心", to: "/news1" },
        { name: "政策法规", to: "/news2" },
        { name: "证书查验", to: "/a" },
        { name: "培训课程", to: "/a" },
        { name: "在线学习", to: "/a" },
        { name: "学习交流", to: "/news3" },
        { name: "在线考试", to: "/a" },
        { name: "学员中心", to: "/a" },
        { name: "资料下载", to: "/a" },
      ],
    };
  },
};
</script>


<style>
@import "./App.css";
@import "./base.css";
@import "./common.css";
@import "./index.css";
@import "./page.css";
@import "./list_content.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
