<template>
<div style="width:1200px;margin: auto;background-color:aliceblue">
    <h2
        style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:0;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-align:center;line-height:150%;background:rgb(255,255,255)">
        <strong><span style="font-family: 黑体;line-height: 150%;letter-spacing: 0;font-size: 20px"><span
                    style="font-family:黑体">四川大国工匠科技教育有限公司简介</span></span></strong></h2>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:16px">2018年7月，在叙永县工商部门注册登记成立四川大国工匠教育科技有限公司，2019年8月，公司获批为全国24个住房和城乡建设行业职业技能鉴定工作试点单位之一；2021年受邀参加中建协组织的《建筑工人职业技能等级认定标准》编写工作；组织建筑工人职业技能培训和建筑劳务输出。 公司总结出“家门初训”、“学校集训”、“工地实训”、“网络复训”的建筑产业工人培育模式，开发建筑工人数据中心、远程视频培训平台、建筑劳务管理系统的手机APP——工匠宝;开发钢筋工、砌筑工、测量工等9个工种57个建筑工人职业技能培训视频，2021年又开发建筑工人VR安全体验和质量样板展示课件48项。制订建筑工人行为规范管理手册，从培训建筑工人有灵魂、有本领、有血性、有品德入手，运用“互联网+建筑工人”模式，对建筑工人培育进行全方位追踪、精细化管理，把企业军队化、工人军人化，培训实战化，市场战争化，努力打造技能水平高、安全意识强，纪律严明，乐于奉献，能打硬仗的“川筑劳务”品牌的建筑工人队伍。通过准军事化的训练，提升了建筑工人的战斗力、执行力、凝聚力，培训后的建筑工人得到了中建八局、中铁二局等大型央企的高度认可和肯定。</span>
    </p>
</div>
    
</template>
