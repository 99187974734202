<template>
<div style="width:1200px;margin: auto;background-color:aliceblue;text-align: left;">
    <h2
        style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:0;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-align:center;line-height:150%;">
        <strong><span style="font-family: 黑体;line-height: 150%;letter-spacing: 0;font-size: 14px"><span
                    style="font-family:黑体">泸州市城镇老旧小区改造流动现场会走进叙永古蔺</span></span></strong></h2>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px">8月18日，全市城镇老旧小区改造流动现场会走进叙永古蔺。市政府副秘书长黄智勇，市住建、城管、经信、民政、通讯、电力等部门、单位负责人，各区县政府分管领导、住建部门负责人参加会议。</span>
    </p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">当天，与会人员冒着高温，先后来到叙永县小南海老旧小区、古蔺县胜利桥还房</span>2、3号楼-国土局还房老旧小区、滨河路片区改造项目现场，实地观摩了解老旧小区改造推进措施、成效。</span>
    </p>
    <p style="text-indent: 24px; line-height: 150%; text-align: center;"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><img
                src="1.jpg"
                title="1629429190325032385.jpg" alt="老旧小区1.jpg" /></span></p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">位于叙永县西大街、南大街和永宁路之间小南海片区改造项目涉及</span>25栋散居楼栋、677户居民。大部分建成于上世纪八九十年代，电力、通讯等管线杂乱，雨污管网、消防等基础配套设施薄弱。在改造过程中，叙永县建立“现场推进、分级决策、共管监督、资金监管”四大推进机制，重点对群众最关心的道路、雨污管网、供水管网和消防等基础配套设施进行改造，发挥社区支部党建引领基层（小区）治理作用，实现改造一片，治理一片。截至目前，小南海片区老旧小区改造项目已完成总工程量的47%。</span>
    </p>
    <p style="text-indent: 24px; line-height: 150%; text-align: center;"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><img
                src="2.jpg"
                title="1629429229196029276.jpg" alt="老旧小区2.jpg" /></span></p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">相对小南海片区而言，位于古蔺县金兰街道的滨河路片区改造项目算是一个</span>“大块头”，该项目涉及29个小区、1457户、10000余人。在该项目改造中，古蔺县坚持整体规划、分批实施、连片打造、结合棚改，将沿滨河路的29个小区95栋散居楼房通过科学规划，整合为4个小区进行改造，同时将滨河路配套基础设施、店招店牌、空调机位等改造和相邻的齐安宫棚户区改造相结合，形成特色鲜明的河畔景致。</span>
    </p>
    <p style="text-indent: 24px; line-height: 150%; text-align: center;"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><img
                src="3.jpg"
                title="1629429262454023607.jpg" alt="老旧小区3.jpg" /></span></p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">会议强调，要高品质完成城镇老旧小区改造工作。要以群众意愿为前提，将旧改项目与片区营城创新及城市更新、海绵城市建设、历史文化保护、完善社区功能补短板等相结合，盘活项目片区闲置资源，促进城市有机更新和新场景营造，最大限度保留文化记忆和城市肌理，营造新空间、植入新产业，讲好泸州故事，打造美好生活城市新空间。加快推进</span>10个重点示范项目落地见效，加快形成泸州老旧小区改造示范效应。</span>
    </p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">要高效率实施城镇老旧小区改造工作。要高效保质加快完成年度改造目标任务，</span>“倒排工期、挂图作战”，建立物业管理等长效机制，高质量谋划推进2022年改造项目规划设计工作。</span>
    </p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:宋体;line-height:150%;font-size:12px"><span
                style="font-family:宋体">要围绕项目推进、国家和省市有关政策文件落实情况、民生实事目标任务完成情况、项目资金绩效和质量安全等开展工作评价和自查自纠。市级相关部门负责人对工作提出相关要求。</span></span>
    </p>
    <p><br /></p>
</div>
</template>
