<template>
<div style="width:1200px;margin: auto;background-color:aliceblue">
    <h2
        style="margin-top:0;margin-right:0;margin-bottom:10px;margin-left:0;text-indent:0;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-align:center;line-height:150%;">
        <strong><span style="font-family: 黑体;line-height: 150%;letter-spacing: 0;font-size: 20px"><span
                    style="font-family:黑体">联系我们</span></span></strong></h2>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:黑体;line-height:150%;font-size:16px">单位名称：四川大国工匠科技教育有限公司</span>
    </p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:黑体;line-height:150%;font-size:16px">地址：四川省泸州市叙永县江门镇九鼎山路561号</span>
    </p>
    <p style="text-indent:24px;text-autospace:ideograph-numeric;line-height:150%"><span
            style=";font-family:黑体;line-height:150%;font-size:16px">联系电话：15881986166</span>
    </p>
    <p style="text-align: center;">
        <img src="contact.png" alt="" />
    </p>
</div>
    
    
</template>