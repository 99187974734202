<template>
  <div class="mk_list">
    <div class="main">
      <div class="location">
        <img id="title" src="@/assets/img_loc.png" />您当前的位置：首页 &gt;
        {{ types[typeKey] }}
      </div>
      <div class="clear"></div>
      <div class="listfl fl">
        <div class="lm" v-if="typeKey == 1">
          <img src="@/assets/img_tzgg.png" />
          <a href="">{{ types[typeKey] }}</a>
        </div>
        <div class="xwlm" v-if="typeKey == 1">
          <ul>
            <li v-for="(item, key) in newsTypes" :key="key">
              <a
                href="#"
                @click.prevent="newsTypeChange(key)"
                target="_blank"
                v-bind:class="{ active: newsTypeIndex == key }"
                >{{ item }}</a
              >
            </li>
          </ul>
        </div>
        <!--

				<div class="lm" style="margin-top: 15px;">
					<img src="static/web/images/img_tzgg.png">
					<a href="">通知公告</a>
				</div>
				<div class="tzbox">
					<div class="news" style="height: 273px;">
						<ul id="tzgg" style="margin-top: -25px;">
							

						<li><a href="web/info/detail/8a405c9579590ca3017964b9beb3001a" target="_blank">泸州市人力资源和社会保障局关于开展2021-2022年度专业技术人员继续教育公需科目学习的通知</a><span>05/13</span></li><li><a href="web/info/detail/8a405c95792279a4017940e856bb0014" target="_blank">关于开展2021-2022年度专业技术人员继续教育公需科目学习的通知</a><span>05/06</span></li><li><a href="web/info/detail/40285c9b70c3a5120171871c5ae00327" target="_blank">泸州市2020年度专业技术人员继续教育公需科目培训开学通知</a><span>04/17</span></li><li><a href="web/info/detail/40285c9b6da41f8f016dec59dcd90090" target="_blank">关于开票延迟的情况说明</a><span>10/21</span></li><li><a href="web/info/detail/40285c9b638aa9ca01638ff18ce70015" target="_blank">业务申请表填写示范（网上密码修改）</a><span>05/24</span></li><li><a href="web/info/detail/8a405c957b16ac39017b28c7025e0018" target="_blank">关于暂停专业技术人员继续教育收费的公告</a><span>08/09</span></li><li><a href="web/info/detail/8a405c9579f06496017a18d226240076" target="_blank">关于及时修改密码的公告</a><span>06/17</span></li></ul>
					</div>
				</div>
				-->
        <div class="btn_a">
          <a href="reg/course" target="_blank">
            <i class="kccx"></i>
            <h2>课程查询</h2>
            <h3>Course Query</h3>
          </a>
          <a href="reg/study" target="_blank">
            <i class="zxxx"></i>
            <h2>在线学习</h2>
            <h3>Online Learning</h3>
          </a>
          <a href="reg/myExam" target="_blank">
            <i class="zxks"></i>
            <h2>在线考试</h2>
            <h3>Online Test</h3>
          </a>
        </div>
      </div>

      <div class="listfr fr">
        <div class="lm_line">
          <span>{{ title }}</span>
        </div>
        <div class="listnews">
          <ul>
            <li v-for="(item, i) in news" :key="i">
              <router-link to="detail"
                ><a href="" target="_blank">
                  <h2>{{ item.title }}</h2>
                  <h3>{{ item.title }}</h3>
                  <h4>{{ item.time }}</h4>
                </a></router-link
              >
            </li>
          </ul>
          <div class="page">
            <div class="pagediv">
              <span class="disabled">上一页</span><span class="current">1</span
              ><a href="javascript:;" class="zxfPagenum nextpage">2</a
              ><a href="javascript:;" class="zxfPagenum">3</a
              ><a href="javascript:;" class="zxfPagenum">4</a
              ><a href="javascript:;" class="zxfPagenum">5</a
              ><a href="javascript:;" class="zxfPagenum">6</a
              ><a href="javascript:;" class="zxfPagenum">7</a
              ><a href="javascript:;" class="zxfPagenum">8</a
              ><a href="javascript:;" class="zxfPagenum">9</a><span>...</span
              ><a href="javascript:;" class="nextbtn">下一页</a
              ><span>共 <b>52</b> 页，</span
              ><span
                >到第<input type="text" class="zxfinput" value="1" />页</span
              ><span class="zxfokbtn">确定</span>
            </div>
            <!--此处分页引用page.js-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.init(this.$route.params);
  },
  beforeRouteUpdate(to, from, next) {
    this.init(to.params);
    next();
  },
  data() {
    return {
      types: { 1: "新闻中心", 2: "政策法规", 3: "学习交流" },
      typeKey: 1,
      newsTypes: { 1: "继教新闻", 2: "职称信息", 3: "培训信息", 4: "时政新闻" },
      newsTypeIndex: 1,
      title: "",
      news: [
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
        {
          title: "泸州市城镇老旧小区改造流动现场会走进叙永古蔺",
          time: "2021-08-20",
        },
      ],
    };
  },
  methods: {
    init(params) {
      this.typeKey = params.type;
      if (this.typeKey == 1) {
        this.title = this.newsTypes[this.newsTypeIndex];
      } else {
        this.title = this.types[this.typeKey];
      }
    },
    newsTypeChange(key) {
      this.newsTypeIndex = key;
      this.title = this.newsTypes[key];
    },
  },
};
</script>